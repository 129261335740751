import React from "react";
import {motion} from "framer-motion";
import { HashLink } from 'react-router-hash-link';

class TopSection extends React.Component{
    render(){

        const divAnimate = {
            hidden:{
                opacity:0,
                x: -25,
                y:-25
            },
            visible:{
                opacity:1,
                x: 0,
                y: 0,
                transition:{duration: 0.3, delay: 1.8, ease:"easeInOut"}
            }
        }

        return(
            <div className="top-container">
                <motion.div className="header-1" variants={divAnimate} initial="hidden" animate="visible"><h3>Hi, my name is</h3></motion.div>
                <motion.div className="header-2" variants={divAnimate} initial="hidden" animate="visible"><h1>Baran Durak</h1></motion.div>
                <motion.div className="header-3" variants={divAnimate} initial="hidden" animate="visible"><h2>I'm a full stack developer</h2></motion.div>
                <motion.div className="main-paragraph" variants={divAnimate} initial="hidden" animate="visible"><p>I'm a software engineer from Turkey/Istanbul. Specializing in building and designing websites and web applications. I've curious to everything!</p></motion.div>
                <motion.div className="header-button" variants={divAnimate} initial="hidden" animate="visible"><HashLink className="button" to="#contact-section" smooth={true}>Contact</HashLink></motion.div>
            </div>
        )
    }
}

export default TopSection;