import React from "react";
import { motion } from "framer-motion";
import _ from "lodash";

import "../scss/work.scss";

//imgs
import TalqMainJpg from "../img/Talq-main.jpg";
import TalqComingJpg from "../img/Talq-coming.jpg";
import TalqRequestJpg from "../img/Talq-request.jpg";
import TalqStatic from "../img/Talq-main-static.jpg";

import BosyappMainJpg from "../img/Bosyapp-main.jpg";
import BosyappDialog from "../img/Bosyapp-dialog.jpg";
import BosyappSearch from "../img/bosyapp-search.jpg";
import BosyappStatic from "../img/Bosyapp-main-static.jpg";

import Spotify1 from "../img/Spotify1.png";
import Spotify2 from "../img/Spotify2.png";
import Spotify3 from "../img/Spotify3.png";
import Spotify4 from "../img/Spotify4.png";
import Spotify5 from "../img/Spotify5.png";

class Work extends React.Component {
  state = {
    talqIndex: 0,
    talqHover: false,
    bosyappIndex: 0,
    bosyappHover: false,
    spotifyIndex: 0,
    spotifyHover: false,
    isOnView: false,
  };

  componentDidMount() {
    this.observer = new IntersectionObserver((entries, observer) => this.handleOnView(entries), {
      threshold: 0.1,
    });

    this.observer.observe(this.containerRef.current);
  }

  handleOnView = (entries) => {
    if (entries[0].intersectionRatio > 0.1) {
      this.setState({ isOnView: true });
    }
  };

  handleImgHover = (imgType) => {
    if (imgType === "talq") {
      // this.setState({talqIndex: Math.floor(Math.random() * 3)})
      if (this.state.talqIndex === 2) this.setState({ talqIndex: 0 });
      else this.setState({ talqIndex: this.state.talqIndex + 1 });
    } else if (imgType === "bosyapp") {
      if (this.state.bosyappIndex === 2) this.setState({ bosyappIndex: 0 });
      else this.setState({ bosyappIndex: this.state.bosyappIndex + 1 });
    } else if (imgType === "spotify") {
      if (this.state.spotifyIndex === 4) this.setState({ spotifyIndex: 0 });
      else this.setState({ spotifyIndex: this.state.spotifyIndex + 1 });
    }
  };

  render() {
    const HexSvg =
      "M258.3,30.9l197,113.7v227.5l-197,113.7l-197-113.7V144.6L258.3,30.9 M258.3,28.5l-199,114.9v229.8l199,114.9l199-114.9V143.4L258.3,28.5L258.3,28.5z";
    const TalqImgs = [TalqMainJpg, TalqComingJpg, TalqRequestJpg];
    const BosyappImgs = [BosyappMainJpg, BosyappDialog, BosyappSearch];
    const SpotifyImgs = [Spotify1, Spotify2, Spotify3, Spotify4, Spotify5];

    //animations
    const pathVariants = {
      hidden: { pathLength: 0 },
      visible: {
        pathLength: [0, 1, 1],
        transition: { ease: "easeInOut", times: [0.2, 0.6, 1], duration: 1.5 },
        rotate: ["90deg", "-90deg", "0deg"],
      },
    };
    const imgVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.5, delay: 1, ease: "easeInOut" } },
    };
    const textVariants = {
      hidden: { opacity: 0, x: "-100%" },
      visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5, ease: "easeInOut" } },
    };

    this.containerRef = React.createRef();
    return (
      <div className="work-container" ref={this.containerRef}>
        <motion.h2
          className="work-mainHeader"
          variants={textVariants}
          initial="hidden"
          animate={this.state.isOnView ? "visible" : ""}
        >
          <i className="bi bi-hexagon"></i>Some of my works
        </motion.h2>
        <div className="work1-wrapper work-wrappers">
          <motion.div
            className="workText-wrapper odd"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "visible" : ""}
          >
            <div className="workHeader-wrapper">
              <span>Web App</span>
              <img className="mobile-img" src={TalqStatic} alt="TalqImg" />
              <h3>TalQ - A real time chat app</h3>
              <p>
                TalQ is a real-time chat web application that you can send friend requests to other
                people with their special ID(like phone number). You can send private messages to
                your friends, keep track of lastest messages, delete friends and message histories
                as well as send and get notifications. Good thing is there is no need to your phone!
                You only need a Google account and internet connection to start using!
              </p>

              <div className="whatTech-wrapper">
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>ReactJs
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>Redux
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>Socket.io
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>ExpressJS
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>NodeJS
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>PassportJs
                  </li>
                </ul>
              </div>
              <div className="work-links">
                <a href="https://talq.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-external-link-alt"></i>
                </a>
                <a
                  href="https://github.com/barandurakk/TalkQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
              </div>
            </div>
          </motion.div>
          <div className="workSvg-container">
            <div className="workSvg-wrapper">
              <svg viewBox="0 0 500 500" preserveAspectRatio="xMidYMid meet" fill="none">
                <motion.path
                  d={HexSvg}
                  variants={pathVariants}
                  initial="hidden"
                  animate={this.state.isOnView ? "visible" : ""}
                />
                <a href="https://talq.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                  <motion.image
                    width="1918"
                    height="937"
                    xlinkHref={this.state.talqHover ? TalqImgs[this.state.talqIndex] : TalqStatic}
                    transform="matrix(0.2454 0 0 0.2454 25 144)"
                    onHoverStart={() => this.setState({ talqHover: true })}
                    onHoverEnd={() => this.setState({ talqHover: false })}
                    whileHover={_.debounce(() => this.handleImgHover("talq"), 350)}
                    variants={imgVariants}
                    initial="hidden"
                    animate={this.state.isOnView ? "visible" : ""}
                  ></motion.image>
                </a>
              </svg>
            </div>
          </div>
        </div>

        <div className="work2-wrapper work-wrappers">
          <div className="workSvg-container">
            <div className="workSvg-wrapper">
              <svg viewBox="0 0 500 500" preserveAspectRatio="xMidYMid meet" fill="none">
                <motion.path
                  d={HexSvg}
                  variants={pathVariants}
                  initial="hidden"
                  animate={this.state.isOnView ? "visible" : ""}
                />
                <a
                  href="https://social-app-f0b76.firebaseapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <motion.image
                    width="1918"
                    height="937"
                    xlinkHref={
                      this.state.bosyappHover ? BosyappImgs[this.state.bosyappIndex] : BosyappStatic
                    }
                    transform="matrix(0.2454 0 0 0.2454 25 144)"
                    onHoverStart={() => this.setState({ bosyappHover: true })}
                    onHoverEnd={() => this.setState({ bosyappHover: false })}
                    whileHover={_.debounce(() => this.handleImgHover("bosyapp"), 350)}
                    variants={imgVariants}
                    initial="hidden"
                    animate={this.state.isOnView ? "visible" : ""}
                  ></motion.image>
                </a>
              </svg>
            </div>
          </div>
          <motion.div
            className="workText-wrapper even"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "visible" : ""}
          >
            <div className="workHeader-wrapper">
              <span>Web App</span>
              <img className="mobile-img" src={BosyappStatic} alt="BosyappImg" />
              <h3>Bosyapp - A social media app</h3>
              <p>
                Bosyapp is basically a twitter clone made with FERN stack. You can send posts, like
                posts and comment on posts also there is a working notification system. You can
                search people by their handles, you can search spesific tags and mentions. After you
                send a post, can update it with in 2 minutes, and delete them any time you want.
              </p>
              <div className="whatTech-wrapper">
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>ReactJs
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>Redux
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>Firebase
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>ExpressJS
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>NodeJS
                  </li>
                </ul>
              </div>
              <div className="work-links">
                <a
                  href="https://social-app-f0b76.firebaseapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-external-link-alt"></i>
                </a>
                <a
                  href="https://github.com/barandurakk/bosyapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="work3-wrapper work-wrappers">
          <motion.div
            className="workText-wrapper even"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "visible" : ""}
          >
            <div className="workHeader-wrapper">
              <span>Web App</span>
              <img className="mobile-img" src={Spotify1} alt="SpotifyImg" />
              <h3>SpotifyJS - Spotify Profile Analysis and Social App</h3>
              <p>
                SpotifyJS built for users to see their detailed profile analysis and understand
                their own music taste. Also users can be friend and see each others music taste as
                well. I'm still developing this platform because I think spotify can be better with
                a little bit of social mechanics.
              </p>
              <div className="whatTech-wrapper">
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>ReactJs
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>Redux Toolkit
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>Spotify API
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>ExpressJS
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="bi bi-hexagon"></i>TypeScript
                  </li>
                  <li>
                    <i className="bi bi-hexagon"></i>NodeJS
                  </li>
                </ul>
              </div>
              <div className="work-links">
                <a
                  href="https://github.com/barandurakk/SpotifyJS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
              </div>
            </div>
          </motion.div>
          <div className="workSvg-container">
            <div className="workSvg-wrapper">
              <svg viewBox="0 0 500 500" preserveAspectRatio="xMidYMid meet" fill="none">
                <motion.path
                  d={HexSvg}
                  variants={pathVariants}
                  initial="hidden"
                  animate={this.state.isOnView ? "visible" : ""}
                />
                <a
                  href="https://github.com/barandurakk/SpotifyJS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <motion.image
                    width="1918"
                    height="937"
                    xlinkHref={
                      this.state.spotifyHover ? SpotifyImgs[this.state.spotifyIndex] : Spotify1
                    }
                    transform="matrix(0.2454 0 0 0.2454 25 144)"
                    onHoverStart={() => this.setState({ spotifyHover: true })}
                    onHoverEnd={() => this.setState({ spotifyHover: false })}
                    whileHover={_.debounce(() => this.handleImgHover("spotify"), 350)}
                    variants={imgVariants}
                    initial="hidden"
                    animate={this.state.isOnView ? "visible" : ""}
                  ></motion.image>
                </a>
              </svg>
            </div>
          </div>
        </div>

        <h2 className="work-mainHeader otherWorks">
          <i className="bi bi-hexagon"></i>My other small projects
        </h2>

        <div className="other-works-container">
          <div className="row">
            <div className="col-xl-4">
              <div className="other-works-item">
                <div className="actions-wrapper">
                  <i className="fas fa-folder"></i>
                  <a
                    href="https://github.com/barandurakk/EmailCamp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </div>
                <div className="detail-wrapper">
                  <h3>EmailCamp - Survey/Newsletter Sender</h3>
                  <p>
                    Emailcamp is a web application where you can organize simple surveys and send
                    e-newsletters to your users via email.
                  </p>
                </div>
                <ul className="whatUse-wrapper">
                  <li>ReactJS</li>
                  <li>MongoDB</li>
                  <li>NodeJs</li>
                  <li>ExpressJs</li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="other-works-item">
                <div className="actions-wrapper">
                  <i className="fas fa-folder"></i>
                  <a
                    href="https://github.com/barandurakk/broHouseApp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </div>
                <div className="detail-wrapper">
                  <h3>BroHouse - ToDo App</h3>
                  <p>
                    It's a to do app for my house. Me and my roommates can collabrate on what to do
                    and what we have done.
                  </p>
                </div>
                <ul className="whatUse-wrapper">
                  <li>ReactJS</li>
                  <li>ExpressJS</li>
                  <li>NodeJs</li>
                  <li>MongoDB</li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="other-works-item">
                <div className="actions-wrapper">
                  <i className="fas fa-folder"></i>
                  <a
                    href="https://www.dcscustoms.com.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </div>
                <div className="detail-wrapper">
                  <h3>DCS - A website for my client</h3>
                  <p>It's a wordpress website for "Digital Customs Services".</p>
                </div>
                <ul className="whatUse-wrapper">
                  <li>Wordpress</li>
                  <li>Javascript</li>
                  <li>HTML / CSS3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Work;
