import React, {Fragment} from "react"
import posed from 'react-pose';
import { tween } from 'popmotion';
import { interpolate } from 'flubber';

import "../scss/header.scss";

const paths = {
    triangle:
    "M25,10.7l0.1,0.2l2.1,3.7l1.8,3.1l1.9,3.3l1.7,3l3.5,6.1l3,5.3l1.8,3.1l2.5,4.3l2.7,4.8l0.3,0.5h-0.7h-4.5h-4.6h-9.2H23h-8.5H7.1H3.4l0.2-0.3l2.3-4l4-7l2.4-4.2l2.6-4.6l2.5-4.4l2.3-4l3.3-5.8L25,10.7 M25,6.7l-1.4,2.5l-2.1,3.6l-3.3,5.8l-2.3,4l-2.5,4.4l-2.6,4.6l-2.4,4.2l-4,7l-2.3,4L0,50h7.1h7.4H23h4.4h9.2h4.6h4.5H50l-2-3.5l-2.7-4.8l-2.5-4.3l-1.8-3.1l-3-5.3L34.4,23l-1.7-3l-1.9-3.3L29,13.6l-2.1-3.7L25,6.7L25,6.7z",
    rect:
    "M45,5v2.2v5.1v4.5v6.5v6.3v3.9v5.4v4.5V45h-5.8H31h-6.5h-5.8h-6.1H5.9H5v-2v-4.3v-7.8v-6.5v-6.9v-5.3V6.7V5h6.4H18h7h5.7h7.6H45 M47,3h-8.7h-7.6H25h-7h-6.6H3v3.7v5.5v5.3v6.9v6.5v7.8V43v4h2.9h6.8h6.1h5.8H31h8.2H47v-3.6v-4.5v-5.4v-3.9v-6.3v-6.5v-4.5V7.2V3L47,3z",
    hexa: 
    "M34.1,2l1.36,2.37,1.78,3.08,2.28,3.94L42,15.76l2.65,4.59-1.88,3.26-2.12,3.66-1.95,3.4-2.38,4.11L34.1,38.7H12.9L2.31,20.35l1.81-3.13L6.38,13.3l2.91-5,2.13-3.69L12.9,2H34.1m1.15-2H11.75L9.69,3.57,7.56,7.26l-2.91,5L2.39,16.22,0,20.35,11.75,40.7h23.5l2.84-4.92,2.38-4.11,2-3.4,2.11-3.66L47,20.35l-3.23-5.59-2.52-4.37L39,6.45,37.2,3.37,35.25,0Z",
    circle:
    "M25,3c12.1,0,22,9.9,22,22s-9.9,22-22,22S3,37.1,3,25S12.9,3,25,3 M25,1C11.7,1,1,11.7,1,25s10.7,24,24,24s24-10.7,24-24S38.3,1,25,1L25,1z"
  };

  const pathIds = Object.keys(paths);

  const morphTransition = ({ from, to }) =>
  tween({
    from: 0 ,
    to: 1
  }).pipe(interpolate(from, to));


  const Path = posed.path(
    
    pathIds.reduce((config, id) => {
      config[id] = {
        d: paths[id],
        transition: morphTransition,
      };
      return config;
    }, {})


);

  class Logo extends React.Component{

    state = { 
        pathIndex: this.props.logoShape,
        pathColor: "primary"
        };

    render(){
        return(
        <Fragment>
            <svg className="letter-svg" viewBox="0 0 50 50" width="50" height="50">
                <path d="M19,39.7c-0.1-0.1-0.2-0.2-0.2-0.4V23c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h6.8c1.8,0,3.1,0.4,4,1.3
                        c0.9,0.9,1.4,2,1.4,3.3c0,0.9-0.2,1.7-0.7,2.3c-0.4,0.6-0.9,1.1-1.5,1.4c0.7,0.4,1.3,0.9,1.8,1.6c0.5,0.7,0.7,1.6,0.7,2.5
                        c0,0.9-0.2,1.8-0.6,2.6c-0.4,0.8-1.1,1.4-1.9,1.8c-0.8,0.5-1.8,0.7-3,0.7h-7C19.3,39.9,19.1,39.9,19,39.7z M25.9,29.8
                        c1.1,0,1.9-0.2,2.5-0.7c0.6-0.5,0.9-1.2,0.9-2.1c0-0.9-0.3-1.5-0.8-2c-0.6-0.5-1.4-0.7-2.5-0.7h-4.7v5.5H25.9z M26.2,37.9
                        c1.1,0,1.9-0.3,2.5-0.9s0.9-1.3,0.9-2.2c0-0.9-0.3-1.7-0.9-2.2s-1.5-0.9-2.5-0.9h-4.9v6.2H26.2z"/>
                </svg>
            <svg className={`shapes-svg ${this.props.logoShape===0 ? "" : this.props.logoShape===1 ? "square" : this.props.logoShape===2 ? "poly" : "circle"}`} viewBox="0 0 50 50" width="60" height="60">
                <Path className={`shape-path`} pose={pathIds[this.props.logoShape]}/>
            </svg>
            
        </Fragment>
        )
    }

}

export default Logo;