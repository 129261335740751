import React from "react";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

import "../scss/aboutMe.scss";
import BaranDurakHover from "../img/BaranDurak-secondary.png";
import baranDurakJpg from "../img/BaranDurak-original.png";

class AboutMe extends React.Component {
  state = { onHover: false, isOnView: false };

  componentDidMount() {
    this.observer = new IntersectionObserver((entries, observer) => this.handleOnView(entries), {
      threshold: 0.25,
    });

    this.observer.observe(this.containerRef.current);
  }

  handleOnView = (entries) => {
    if (entries[0].intersectionRatio > 0.25) {
      this.setState({ isOnView: true });
    }
  };

  render() {
    const pathVariants = {
      hover: { pathLength: 1, rotate: 180, transition: { duration: 0.5, ease: "easeInOut" } },
      hidden: { pathLength: 0 },
      visible: { pathLength: 1, rotate: 90, transition: { duration: 0.5, ease: "easeInOut" } },
    };

    const imgVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.5, delay: 0.5, ease: "easeInOut" } },
    };

    const textVariants = {
      hidden: { opacity: 0, x: "-100%" },
      hiddenUl: { opacity: 0, x: "100%" },
      h1: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.4, ease: "easeInOut" } },
      h2: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.5, ease: "easeInOut" } },
      h3: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.6, ease: "easeInOut" } },
      h4: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.7, ease: "easeInOut" } },
      h5: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.8, ease: "easeInOut" } },
      ul: { opacity: 1, x: 0, transition: { duration: 0.4, delay: 0.9, ease: "easeInOut" } },
    };

    const rectSvg = "M1.9,248.7L251.5-2.8l250.5,251.5L252.4,498.2L1.9,248.7z";

    this.containerRef = React.createRef();

    return (
      <div className="about-container" ref={this.containerRef}>
        {!isMobile ? (
          <div className="aboutSvg-wrapper">
            <svg viewBox="0 -5 900 900" preserveAspectRatio="xMidYMid meet" fill="none">
              <motion.path
                d={rectSvg}
                variants={pathVariants}
                initial="hidden"
                animate={this.state.onHover ? "hover" : this.state.isOnView ? "visible" : ""}
                fill="none"
              />
              <motion.image
                width="720"
                height="720"
                xlinkHref={this.state.onHover ? baranDurakJpg : BaranDurakHover}
                transform="matrix(0.5444 0 0 0.5444 55.9457 52.2167)"
                variants={imgVariants}
                initial="hidden"
                animate={this.state.isOnView ? "visible" : ""}
                onHoverStart={() => this.setState({ onHover: true })}
                onHoverEnd={() => this.setState({ onHover: false })}
              ></motion.image>
            </svg>
          </div>
        ) : (
          <div className="aboutSvg-wrapper">
            <svg
              viewBox="-50 -50 600 600 "
              width="300"
              height="300"
              preserveAspectRatio="xMidYMid meet"
              fill="none"
            >
              <motion.path
                width="200"
                height="200"
                d={rectSvg}
                variants={pathVariants}
                initial="hidden"
                animate={this.state.onHover ? "hover" : this.state.isOnView ? "visible" : ""}
                fill="none"
              />
              <motion.image
                width="720"
                height="720"
                xlinkHref={this.state.onHover ? baranDurakJpg : BaranDurakHover}
                transform="matrix(0.5444 0 0 0.5444 55.9457 52.2167)"
                variants={imgVariants}
                initial="hidden"
                animate={this.state.isOnView ? "visible" : ""}
                onTap={() => this.setState({ onHover: !this.state.onHover })}
              ></motion.image>
            </svg>
          </div>
        )}
        <div className="aboutText-wrapper">
          <motion.h2
            className="about-header"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "h1" : ""}
          >
            <i className="far fa-square"></i>About Me
          </motion.h2>
          <motion.p
            className="about-paragraph"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "h2" : ""}
          >
            Hi! I'm Baran, a software engineer from Turkey, Istanbul.
          </motion.p>
          <motion.p
            className="about-paragraph"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "h3" : ""}
          >
            I love learning new things, understand how systems work and build something new for the
            internet. I mostly build web and mobile applications. Also sometimes design them.
            Basically, I love to give functionality to a button :)
          </motion.p>
          <motion.p
            className="about-paragraph"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "h4" : ""}
          >
            I am currently a software engineer at ATEZ, one of Turkey's blockchain R&D companies and
            working on my personal projects.
          </motion.p>
          <motion.p
            className="about-paragraph"
            variants={textVariants}
            initial="hidden"
            animate={this.state.isOnView ? "h5" : ""}
          >
            Here are a few technologies I've been working with:
          </motion.p>

          <motion.div
            className="skills-wrapper"
            variants={textVariants}
            initial="hiddenUl"
            animate={this.state.isOnView ? "ul" : ""}
          >
            <ul>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer">
                  NodeJS
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                  ReactJS
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>Javascript (ES6)
              </li>
            </ul>
            <ul>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://svelte.dev/" target="_blank" rel="noopener noreferrer">
                  Svelte
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://wordpress.org/" target="_blank" rel="noopener noreferrer">
                  Wordpress
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  PostgreSQL
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://expressjs.com/" target="_blank" rel="noopener noreferrer">
                  Solidty
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer">
                  TypeScript
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>Loopback/ExpressJS
              </li>
            </ul>
            <ul>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://expressjs.com/" target="_blank" rel="noopener noreferrer">
                  NextJS
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>
                <a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer">
                  MongoDB
                </a>
              </li>
              <li>
                <i className="fas fa-square-full"></i>HTML5 / (S)CSS
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
    );
  }
}

export default AboutMe;
