import React from "react";
import { Fragment } from "react";
import Header from "../components/Header";

import "../scss/landing.scss";
import "../scss/header.scss";
import "../scss/topSection.scss";
import "../scss/work.scss";

import TopSection from "../components/TopSection";
import AboutMe from "../components/AboutMe";
import Work from "../components/Work";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import FixedIcons from "../components/FixedIcons";

class Landing extends React.Component {
  state = {
    logoShape: 0,
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(
      (entries, observer) => this.handleLogoAndView(entries, observer),
      { threshold: [0.15, 0.6] }
    );

    this.observer.observe(this.topRef.current);
    this.observer.observe(this.aboutRef.current);
    this.observer.observe(this.workRef.current);
    this.observer.observe(this.contactRef.current);
  }

  handleLogoAndView = (entries, observer) => {
    //handleLogo
    if (
      entries[0].target.id === "top-section" &&
      entries[0].intersectionRatio > 0.6 &&
      this.state.logoShape !== 0
    ) {
      this.setState({ logoShape: 0 });
    } else if (
      entries[0].target.id === "about-section" &&
      entries[0].intersectionRatio > 0.6 &&
      this.state.logoShape !== 1
    ) {
      this.setState({ logoShape: 1 });
    } else if (
      entries[0].target.id === "work-section" &&
      entries[0].intersectionRatio > 0.15 &&
      this.state.logoShape !== 2
    ) {
      console.log("hexa");
      this.setState({ logoShape: 2 });
    } else if (
      entries[0].target.id === "contact-section" &&
      entries[0].intersectionRatio > 0.6 &&
      this.state.logoShape !== 3
    ) {
      this.setState({ logoShape: 3 });
    }
  };

  render() {
    this.aboutRef = React.createRef();
    this.workRef = React.createRef();
    this.topRef = React.createRef();
    this.contactRef = React.createRef();
    return (
      <Fragment>
        <FixedIcons />
        <Header logoShape={this.state.logoShape} />
        <div id="content">
          <section id="top-section" className="sections" ref={this.topRef}>
            <TopSection />
          </section>
          <section id="about-section" className="sections" ref={this.aboutRef}>
            <AboutMe />
          </section>
          <section id="work-section" className="sections" ref={this.workRef}>
            <Work />
          </section>
          <section id="contact-section" className="sections" ref={this.contactRef}>
            <Contact />
          </section>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Landing;
