import React from "react";
import Logo from "./Logo";
import { HashLink } from 'react-router-hash-link';
import { Fragment } from "react";
import {motion} from "framer-motion";


class Header extends React.Component {

  state={
    onTop: true,
    isOpen: false
  }

  componentDidMount(){
    window.addEventListener('scroll', ()=>{
      if(window.scrollY === 0){
        this.setState({onTop: true});
      }else if(window.scrollY > 0 && this.state.onTop){
        this.setState({onTop: false});
      }
    }, 
    { passive: true });
    
  }

  render(){

    const navAnimate = {
      hidden:{
        opacity:0,
        y:"-150%"
      },
      first:{opacity: 1, y:0, transition:{duration:0.5, delay: 1.5, ease: "easeInOut"}},
      second:{opacity: 1, y:0, transition:{duration:0.5, delay: 1.6, ease: "easeInOut"}},
      third:{opacity: 1, y:0, transition:{duration:0.5, delay: 1.7,ease: "easeInOut"}},
      resume:{opacity: 1, y:0, transition:{duration:0.5, delay: 1.8, ease: "easeInOut"}},
    }

    return(
      <Fragment>
      <div className={`nav-container ${this.state.onTop ? "on-top" : "not-top"}`}>  
        <div className="logo-wrapper">
            <HashLink to="#top-section" smooth={true} ><Logo logoShape={this.props.logoShape}/></HashLink>
        </div>
        <div className="nav-menu">
            <ul className="nav-items">
                <motion.li variants={navAnimate} initial="hidden" animate="first"><HashLink to="#about-section" smooth={true}>About</HashLink></motion.li>
                <motion.li variants={navAnimate} initial="hidden" animate="second"><HashLink to="#work-section" smooth={true}>Work</HashLink></motion.li>
                <motion.li variants={navAnimate} initial="hidden" animate="third"><HashLink to="#contact-section" smooth={true}>Contact</HashLink></motion.li>
                <motion.li variants={navAnimate} initial="hidden" animate="resume"><a href="https://barandurak.com/resume.pdf" className="resume-button" target="_blank" rel="noopener noreferrer">Resume</a></motion.li>
            </ul>
        </div>
        <button className="burger-button" onClick={() => this.setState({isOpen: !this.state.isOpen})}>
          <div className={`${this.state.isOpen ? "open" : ""}`} />
          <div className={`${this.state.isOpen ? "open" : ""}`} />
          <div className={`${this.state.isOpen ? "open" : ""}`} />
        </button>
        <div className={`nav-sidebar ${this.state.isOpen ? "open" : ""}`}>
          <ul className="mobile-nav-items">
                <li><HashLink to="#about-section" smooth={true} onClick={() => this.setState({isOpen: !this.state.isOpen})}>About</HashLink></li>
                <li><HashLink to="#work-section" smooth={true} onClick={() => this.setState({isOpen: !this.state.isOpen})}>Work</HashLink></li>
                <li><HashLink to="#contact-section" smooth={true} onClick={() => this.setState({isOpen: !this.state.isOpen})}>Contact</HashLink></li>
                <li><a href="https://barandurak.com/resume.pdf" className="resume-button" target="_blank" rel="noopener noreferrer">Resume</a></li>
            </ul>
            <div className="mobile-icons">
                    <a href="https://www.linkedin.com/in/baran-durak-b5ab01151/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href="https://github.com/barandurakk/TalkQ" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                    <a href="https://open.spotify.com/playlist/4gwwjlsQP6EfXzBpgJuDZ9?si=eOEdqrdeR62SCi5K0sqTBQ" target="_blank" rel="noopener noreferrer"><i className="fab fa-spotify"></i></a>
                    <a href="https://www.instagram.com/baran.durak/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="http://twitter.com/barandurock?s=08" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>                   
                </div>
        </div>
      </div>   
      <div className={`blur-layer ${this.state.isOpen ? "open" : ""}`} onClick={() => this.setState({isOpen: false})}></div> 
      </Fragment>
    )
  } 
}

export default Header;
