const validateFormData = (formData) => {

    let errorMessages = {};
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if(formData.from_name.length === 0){
        errorMessages.name = "Can't be empty!"
    }else if(formData.from_name.length > 200){
        errorMessages.name = "Too long!"
    }

    if(formData.message.length === 0){
        errorMessages.message = "Can't be empty!"
    }else if(formData.message.length > 10000){
        errorMessages.message = "Too long!"
    }

    if(formData.subject.length === 0){
        errorMessages.subject = "Can't be empty!"
    }else if(formData.subject.length > 500){
        errorMessages.subject = "Too long!"
    }

    if(formData.email.length === 0){
        errorMessages.email = "Can't be empty!"
    }else if(formData.email.length > 200){
        errorMessages.email = "Too long!"
    }else if(!emailReg.test(formData.email)){
        errorMessages.email = "Please enter a valid email!"
    }
    
    
    return errorMessages;

}

export default validateFormData;