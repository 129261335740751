import React from "react";
import { motion } from "framer-motion";
import emailjs, { init } from "emailjs-com";
import Loader from "react-loader-spinner";
import _ from "lodash";
import validateFormData from "../util/formValidate";

import "../scss/contact.scss";

class Contact extends React.Component {
  state = {
    isOnView: false,
    loading: false,
    sended: false,
    name: "",
    subject: "",
    message: "",
    email: "",
    errorMessages: {},
  };

  componentDidMount() {
    init("user_Jc3PxTIoAf7aUuMwMa6WM");

    this.observer = new IntersectionObserver((entries, observer) => this.handleOnView(entries), {
      threshold: 0.1,
    });

    this.observer.observe(this.containerRef.current);
  }

  handleOnView = (entries) => {
    if (entries[0].intersectionRatio > 0.1) {
      this.setState({ isOnView: true });
    }
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      reply_to: this.state.email,
      subject: this.state.subject,
    };

    //validate email
    const formErrors = validateFormData(templateParams);

    if (_.isEmpty(formErrors)) {
      //send email
      this.setState({ loading: true });
      emailjs
        .send("service_sq3c0df", "template_23f4f9j", templateParams)
        .then(() => {
          this.setState({ sended: true, loading: false, errorMessages: {} });
        })
        .catch((err) => {
          this.setState({ errorMessage: err.message, loading: false });
        });
    } else {
      this.setState({ errorMessages: formErrors });
    }
  };

  render() {
    const iconAnimate = {
      hidden: {
        opacity: 0,
        scale: 50,
        rotate: "360deg",
        transformOrigin: "50% 60%",
        display: "none",
      },
      visible: {
        display: "block",
        opacity: 1,
        scale: 1,
        rotate: "0deg",
        transition: { duration: 0.8, ease: "easeInOut" },
      },
    };

    const h2Animate = {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: { duration: 2, ease: "easeInOut" },
      },
    };

    const formAnimate = {
      hidden: {
        opacity: 0,
        y: "-45%",
      },
      visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 0.8, ease: "easeInOut" },
      },
    };

    this.containerRef = React.createRef();
    return (
      <div className="contact-container" ref={this.containerRef}>
        <div className="contact-header-wrapper">
          <motion.h2
            variants={h2Animate}
            initial="hidden"
            animate={this.state.isOnView ? "visible" : ""}
          >
            C
            <motion.span
              variants={iconAnimate}
              initial="hidden"
              animate={this.state.isOnView ? "visible" : ""}
            >
              o
            </motion.span>
            ntact Me
          </motion.h2>
        </div>
        <div className="form-container">
          <motion.form
            onSubmit={(e) => this.handleSubmit(e)}
            variants={formAnimate}
            initial="hidden"
            animate={this.state.isOnView ? "visible" : ""}
          >
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.handleOnChange(e)}
            />
            {this.state.errorMessages.name ? (
              <div className="error-messages">{this.state.errorMessages.name}</div>
            ) : null}
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={this.state.email}
              onChange={(e) => this.handleOnChange(e)}
            />
            {this.state.errorMessages.email ? (
              <div className="error-messages">{this.state.errorMessages.email}</div>
            ) : null}
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              value={this.state.subject}
              onChange={(e) => this.handleOnChange(e)}
            />
            {this.state.errorMessages.subject ? (
              <div className="error-messages">{this.state.errorMessages.subject}</div>
            ) : null}
            <label>Message</label>
            <textarea
              type="text"
              rows="4"
              name="message"
              value={this.state.message}
              onChange={(e) => this.handleOnChange(e)}
            />
            {this.state.errorMessages.message ? (
              <div className="error-messages">{this.state.errorMessages.message}</div>
            ) : null}
            {this.state.loading ? (
              <div className="form-loading">
                <Loader type="TailSpin" color="#64ffda" height={50} width={50} />
              </div>
            ) : this.state.sended ? (
              <div className="form-sended">Sended!</div>
            ) : !_.isEmpty(this.state.errorMessages) ? (
              <button type="submit">Send</button>
            ) : (
              <button type="submit">Send</button>
            )}
          </motion.form>
        </div>
      </div>
    );
  }
}

export default Contact;
