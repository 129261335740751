import React from "react";
import { Fragment } from "react";


import "../scss/fixedIcons.scss";

class FixedIcons extends React.Component{
    render(){


        return(
            <Fragment>
                <div className="fixed-leftSide">
                    <a href="https://www.linkedin.com/in/baran-durak-b5ab01151/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href="https://github.com/barandurakk/TalkQ" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                    <a href="https://open.spotify.com/playlist/4gwwjlsQP6EfXzBpgJuDZ9?si=eOEdqrdeR62SCi5K0sqTBQ" target="_blank" rel="noopener noreferrer"><i className="fab fa-spotify"></i></a>
                    <a href="https://www.instagram.com/baran.durak/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="http://twitter.com/barandurock?s=08" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>                   
                    <div className="line"></div>
                </div>
                <div className="fixed-rightSide">
                    <span>barandurak07@gmail.com</span>
                    <div className="line"></div>
                </div>
            </Fragment>
        )
    }
}

export default FixedIcons;