import React from "react"

import "../scss/footer.scss";

class Footer extends React.Component{
    render(){
        return(
            <div className="footer-container">
                    <div className="footer-content">
                        <span>Developed and built by <a className="name-tag" href="https://barandurak.com">Baran Durak</a></span>
                    </div>
            </div>
        )
    }
}

export default Footer;