import React from "react";
import { Router, Route } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";

//components

import Landing from "./pages/landing.js";

export const history = createBrowserHistory();

class App extends React.Component {
  componentDidMount() {
    const ele = document.getElementById("ipl-progress-indicator");
    const content = document.getElementById("content");
    if (content) {
      content.style.display = "none";

      if (ele) {
        setTimeout(() => {
          content.style.display = "block";
          ele.outerHTML = "";
        }, 1400);
      }
    }
  }

  render() {
    return (
      <Router history={history}>
        <Route exact path="/" component={Landing} />
      </Router>
    );
  }
}

export default App;
